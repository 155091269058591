<script>
import {UContextMenu} from 'uloc-vue'
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"

export default {
  name: 'ContextMenuBoard',
  props: ['task'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    openTask(t, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'console.tarefa', params: {id: t.id}})
      window.open(routeData.href, '_blank')
    }
  },
  components: {
    MenuOptionsItem,
    UContextMenu
  }
}
</script>

<template>
  <u-context-menu ref="context" class="erp-context-menu">
    <ul>
      <menu-options-item @click="openTask(task)" close label="Abrir" icon="folder" label-class=""/>
      <menu-options-item close label="Fechar" icon="times" label-class="text-negative" disabled/>
    </ul>
    <div class="wrapper-sm font-11" style="cursor: default; max-width: 250px">
      Criado em {{ task.createdAt|formatDateDistance }}<br>
      <div class="m-t-xs" v-if="task.modifiedBy">Modificado por {{ task.modifiedByUser }} em {{ task.dateModified|formatDate }}</div>
      <div class="m-t-xs" v-if="task.project">Projeto: {{task.project.name}}</div>
      <div class="m-t-xs" v-if="task.cliente">Cliente: {{task.cliente.name}}</div>
    </div>
  </u-context-menu>
</template>
